<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
          style="position: relative"
          class="nav-items"
        >
          <img
            :src="
              require(`@/assets/svg/navigation/${icon}_${
                selected ? 'selected' : 'unselected'
              }.svg`)
            "
            class="icon-size"
          />
          <div
            v-if="icon == 'chat' && getUnreadMessage"
            class="new-message-count"
          >
            {{ getUnreadMessage > 10 ? "•" : getUnreadMessage }}
          </div>
          <div
            v-else-if="icon == 'notifications' && unreadNotification"
            class="new-message-count"
          >
            {{ unreadNotification > 10 ? "•" : unreadNotification }}
          </div>
        </div>
      </template>
      <span>{{ $t(title) }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    icon: String,
    selected: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconTitles: [
        {
          key: "home",
          title: "home"
        },
        { key: "needs", title: "needs" },
        { key: "shops", title: "shop" },
        { key: "chat", title: "message" },
        { key: "notifications", title: "notifications" }
      ]
    };
  },
  methods: {
    ...mapActions("notification", {
      getUnreadCount: "getUnreadCount"
    })
  },
  computed: {
    ...mapGetters({
      getUnreadMessage: "chat/getUnreadMessagesCount",
      unreadNotification: "notification/getUnreadCount"
    }),
    title() {
      var item = {};
      this.iconTitles.map(t => {
        if (t.key.includes(this.icon)) {
          item = t;
        }
      });
      return item.title;
    }
  },
  watch: {
    "$route.name": function() {
      if (this.icon == "notifications") {
        this.getUnreadCount().catch(error => {
          console.log(error);
        });
      }
    }
  },
  mounted() {
    if (this.icon == "notifications") {
      this.getUnreadCount()
        .then(res => {
          this.notificationCount = res;
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
<style>
.nav-menu {
  background-color: white;
}
.nav-content {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  align-items: center;
}
.nav-content.closed-menu {
  display: none;
}
.mobileViewHam {
  display: none;
}
@media (min-width: 601px) and (max-width: 1100px) {
  .webViewMenu {
    display: none;
  }
  .mobileViewHam {
    display: block;
  }

  .mobilenav {
    position: absolute;
    top: 60px;
    background: #0b4468;
    width: 100%;
    transition: all 0.2s ease-out;
    left: 0;
    height: 84px !important;
  }
  .v-tabs-slider-wrapper {
    bottom: 0px;
  }
  header .v-btn--icon.v-size--default .v-icon {
    color: #fff;
  }
  .logo-mobile .v-image__image.v-image__image--contain {
    background-position: left center !important;
  }
}
@media (max-width: 600px) {
  .logo-mobile .v-image__image.v-image__image--contain {
    background-position: left center !important;
  }
  .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
    min-height: 38px;
    display: none;
  }

  .logo-mobile .v-responsive__content {
    width: 184px !important;
  }
}
@media (max-width: 680px) {
  .logo-mobile .v-responsive__content {
    width: 184px !important;
  }
}
@media (max-width: 350px) {
  .logo-mobile .v-responsive__content {
    width: 125px !important;
  }
  .profile-mobile .v-avatar.elevation-6 {
    width: 28px !important;
    height: 28px !important;
    min-width: 28px !important;
    margin-left: 0px !important;
  }
}

.v-application .profile-mobile {
  display: block !important;
}
</style>

import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const locale = "en"; // default locale

export default new VueI18n({
  locale,
  fallbackLocale: ["es", "pt"],
  messages: {
    en: {
      needs: "Needs",
      language_settings_title: "Language and region Settings",
      reactions: "Reactions",
      all: "All",
      map: "Map",
      calendar: "Calendar",
      agency: "Agency",
      agencies: "Agencies",
      volunteer: "Volunteer",
      volunteers: "Volunteers",
      ads: "Ads",
      business: "Business",
      send_message: "Send message",
      reported: "Reported",
      settings: "Settings",
      notification_settings: "Notification Settings",
      password_settings: "Password Settings",
      site_settings: "Site Settings",
      awards: "Awards",
      contact: "Contact",
      weather: "Weather",
      donate: "Donate Now",
      events: "Events",
      blogs: "Blogs",
      follow_agencies: "Follow Agencies",
      my_agencies: "My Agencies",
      change_makers: "Change makers",
      volunteer_opportunities: "Volunteer Opportunities",
      community_chat_room: "Community Chat Room",
      home: "Home",
      message: "Message",
      notifications: "Notifications",
      Account: "Account",
      Notifications: "Notifications",
      Password: "Password",
      "Language and region": "Language and region",
      Blocked: "Blocked",
      account_settings: "Account Settings",
      account_information: "Account Information",
      account_privacy: "Account Privacy",
      linked_accounts: "Linked Accounts",
      delete_account: "Delete Account",
      no_search_result: "No results were found for your search",
      follower: "follower",
      followers: "followers"
    },
    es: {
      needs: "Necesidades",
      language_settings_title: "Configuración de idioma y región",
      reactions: "Reacciones",
      all: "Todos",
      map: "Mapa",
      calendar: "Calendario",
      agency: "Agencia",
      agencies: "Agencias",
      volunteer: "Voluntaria",
      volunteers: "Voluntarias",
      ads: "Anuncios",
      business: "Negocio",
      send_message: "Enviar mensaje",
      reported: "Reportada",
      settings: "Ajustes",
      notification_settings: "Configuración de las notificaciones",
      password_settings: "Configuración de contraseña",
      site_settings: "Configuración del sitio",
      events: "Eventos",
      blogs: "Blogs",
      awards: "Premios",
      contact: "Contacto",
      weather: "Tiempo",
      donate: "Done ahora",
      follow_agencies: "Seguir agencias",
      my_agencies: "Mis agencias",
      change_makers: "Hacedores de cambio",
      volunteer_opportunities: "Oportunidades de voluntariado",
      community_chat_room: "Sala de chat comunitaria",
      home: "Pantalla",
      message: "Mensaje",
      notifications: "Notificaciones",
      Account: "Cuenta",
      Notifications: "Notificaciones",
      Password: "Clave",
      "Language and region": "Idioma y región",
      Blocked: "Obstruida",
      account_settings: "Configuraciones de la cuenta",
      account_information: "Información de la cuenta",
      account_privacy: "Privacidad de la cuenta",
      linked_accounts: "Cuentas vinculadas",
      delete_account: "Borrar cuenta",
      no_search_result: "No se encontraron resultados para tu búsqueda",
      follower: "seguidor",
      followers: "seguidores"
    },
    pt: {
      needs: "Precisa",
      language_settings_title: "Configurações de idioma e região",
      reactions: "Reações",
      all: "Toda",
      map: "Mapa",
      calendar: "Calendário",
      agency: "Agência",
      agencies: "Agências",
      volunteer: "Voluntária",
      volunteers: "Voluntárias",
      ads: "Publicidades",
      business: "Negócios",
      send_message: "Enviar mensagem",
      reported: "Relatada",
      settings: "Configurações",
      notification_settings: "Configurações de notificação",
      password_settings: "Configuração de senha",
      site_settings: "Configurações do site",
      awards: "Prêmios",
      events: "Eventos",
      blogs: "Blogues",
      contact: "Contato",
      weather: "Tempo",
      donate: "DOE agora",
      follow_agencies: "Siga agências",
      my_agencies: "Minhas agências",
      change_makers: "Agentes de mudança",
      volunteer_opportunities: "Oportunidades de voluntariado",
      community_chat_room: "Sala de bate-papo da comunidade",
      home: "Tela",
      message: "Mensagem",
      notifications: "Notificações",
      Account: "Conta",
      Notifications: "Notificações",
      Password: "Senha",
      "Language and region": "Idioma e região",
      Blocked: "Bloqueado",
      account_settings: "Configurações da conta",
      account_information: "Informação da conta",
      account_privacy: "Privacidade da conta",
      linked_accounts: "Contas ligadas",
      delete_account: "Deletar conta",
      no_search_result: "Não foram encontrados resultados para a sua pesquisa",
      follower: "seguidor",
      followers: "seguidores"
    }
  }
});
